import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import { getErrorMessage } from '../../../actions';
import { Auth } from '../../../actions/Auth';
import store from '../../../store';
import { setError, setLoading } from '../../../actions/action-creators';
import Button from '../Button';
import { InputField } from '../Forms';
import Spinner from 'reactstrap/lib/Spinner';
import moment from 'moment';
export default class ChatBox extends Component {
  state = {
      conversation : [],
      isLoadingConversation : true,
      message : ""
  }

  componentDidMount(){
    this.fetchConversation()
    this.interval = setInterval(this.fetchConversation, 3000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  
  componentDidUpdate(prevProps){
      if(prevProps.connectionData.recruiter_id !== this.props.connectionData.recruiter_id){
        this.setState({isLoadingConversation : true})
        clearInterval(this.interval);
        this.fetchConversation()
        this.interval = setInterval(this.fetchConversation, 3000);
      }
  }

  
  fetchConversation = async () => {
    let  { connectionData } = this.props;
    try {
    var body = {
        recruiter_id : connectionData.recruiter_id,
    }
    let resp = await Auth.recruiterConversation.fetch(body)
    let conversation = resp.data.data
    this.setState({
        conversation, isLoadingConversation : false
    })
    
    var element = document.getElementById("chat-body");
    if(element)
    element.scrollTop = element.scrollHeight;
    } catch (e) {
        console.log(e)
    store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
  }

  sendMessage = async (e) => {
    e.preventDefault();
    let  { connectionData } = this.props;
    let  { message } = this.state;
    if(!message){
            return false;
    }
    store.dispatch(setLoading(true))
    try {
    var body = {
        recruiter_id : connectionData.recruiter_id,
        message : message
    }
    await Auth.recruiterConversation.send(body)
    this.setState({message : ""})
    this.fetchConversation();
    } catch (e) {
        console.log(e)
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    store.dispatch(setLoading(false))

  }
 


  render() {
    let  { connectionData } = this.props;
    let  { isLoadingConversation, conversation, message } = this.state;
    return (
        <Row className="border">
            <Col>
                <Row className="r-bg--blue-3 text-light">
                    <Col className="py-3" xs={10}>
                        <span>{connectionData.company_name}</span>
                    </Col>
                    <Col className="py-3 px-0 text-right" xs={2}>
                        <Button onClick={()=> this.props.setChatBox(null)}><span className="fi flaticon-close"></span></Button>
                    </Col>
                </Row>
                <Row>
                    <Col id="chat-body" style={{height : 250, overflowY : "scroll"}} className="bg-light">
                        {isLoadingConversation && <div className="center-xy"><Spinner animation="border" /></div>}
                    {
                        conversation.map((chat,index) => 
                            <Row key={index} className={"my-2 "+(chat.is_send === 2 ? "justify-content-end":"")}>
                                <Col xs={8} className=" r-text--dark rounded py-3" style={{fontSize: '12px'}}>
                                  {chat.message}
                                </Col>
                                <Col xs={8} className={"border-bottom " + (chat.is_send === 2 ? "text-right":"")}><span className="text-dark r-text--sm">{moment(chat.created_on).format("DD-MMM-YYYY h:m a")}</span></Col>
                            </Row>
                        )
                    }
                    </Col>
                </Row>
                <form onSubmit={this.sendMessage}> 
                <Row className="r-bg--blue-3 align-items-center">
                    <Col xs={9} className="py-3">
                        <InputField value={message} onChange={(e) => this.setState({message : e.target.value})} required name="msg" className="w-100" />
                    </Col>
                    <Col xs={3} className=" px-0">
                        <Button color="dark" className="r-btn r-text--lg r-btn--dark"><span className="fi flaticon-arrow"></span></Button>
                    </Col>
                </Row>
                </form>
            </Col>
        </Row>
       
    )
  }
}
