import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { ButtonDropdown, DropdownToggle, DropdownMenu, Popover, PopoverBody, } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import { CONSTANTS } from '../../../constants';
import NavLink from 'react-router-dom/NavLink';
import { Auth } from '../../../actions/Auth';
import Link from 'react-router-dom/Link';
import withRouter from 'react-router-dom/withRouter';
import { InputField } from '../Forms';
import { ReactComponent as NotificationLogo } from '../../../assets/icons/nav_notifications.svg';
import { ReactComponent as MessagesLogo } from '../../../assets/icons/nav_conversations.svg';
import { ReactComponent as FileManagerLogo } from '../../../assets/icons/nav_filemanager.svg';
import { ReactComponent as CalendarLogo } from '../../../assets/icons/nav_calendar.svg';
import { getTimeElasped, generateRedirectURL } from '../../../actions';
import moment from "moment";
class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navDropdown: false,
      notifications: [],
      showNotifications: false
    };
  }

  componentWillMount() {
    this.init();
  }

  init = async () => {
    try {
      let resp = await Auth.User.getNotifications()
      this.setState({
        notifications: resp.data
      })
    } catch (e) {
      console.log(e)
    }
  }

  toggleNav = () => {
    this.setState({ navDropdown: !this.state.navDropdown });
  }
  setRole = async (role) => {
    let user = JSON.stringify(this.props.userDetail.user)
    this.props.setLoading(true)
    this.props.setUser(null);
    await this.props.setRole(role);
    this.props.setUser(JSON.parse(user))
    this.props.setLoading(false)
    this.toggleNav();
  }

  toggleNotifications = () => {
    this.setState({
      showNotifications: !this.state.showNotifications
    })
  }

  notificationClick = (notification) => {
    let { notifications } = this.state;
    if (notification.notification_status === 0) {
      try {
        Auth.User.updateNotification(notification);
        notifications.forEach(n => {
          if (n.notification_id === notification.notification_id)
            n.notification_status = 1
        })
        this.setState({
          notifications
        })
      } catch (e) { console.log(e) }
    }
    let redirectURL = generateRedirectURL(notification)
    if (redirectURL)
      this.props.history.push(redirectURL);
    setTimeout(() => {
      this.setState({
        showNotifications: false
      })
    }, 0)
  }

  render() {
    const { userDetail, logout } = this.props;
    let { notifications, showNotifications } = this.state;
    let unread = notifications.filter(n => n.notification_status === 0)
    let name = userDetail.user.first_name || userDetail.user.email.split("@")[0];
    let expiryDaysLeft = userDetail.role.role_type === "PT" && userDetail.user.current_role_info.subscription_end_date ? moment(userDetail.user.current_role_info.subscription_end_date).diff(moment(), "days") : null;
    return (
    <>
      <div className="r-nav sticky-top">
        <Container>
          <Row className="align-items-center pr-0">
            <Col xs="auto">
              <Link to="/student/feed" ><img src="/images/reculta_logo.png" className="r-nav__logo" alt="Reculta" /></Link>
            </Col>
            {/* <Col xs="auto" className="pl-0">
              <InputField className="r-nav__search r-radius--2" placeholder="Search" type="text" />
              <span className="fi flaticon-search"></span>
            </Col> */}
            <Col className="p-0">
              <ul className="m-0 d-flex justify-content-end list-unstyled">
                {CONSTANTS.NAV[userDetail.role.role_type] && CONSTANTS.NAV[userDetail.role.role_type].map((menu, i) => {
                  return (
                    ((menu.hasPermission && userDetail.role.hasPermission &&
                      userDetail.role.hasPermission[menu.hasPermission] &&
                      userDetail.role.hasPermission[menu.hasPermission].permission_access.read) || !menu.hasPermission) && <li key={i}><NavLink to={menu.link} className="r-nav__link r-heading-6">{menu.name} </NavLink></li>
                  )
                })}
              </ul>
            </Col>
            <Col xs="auto">
              <ul className="m-0 d-flex list-unstyled">
                {/* <li>
                  <button className="r-nav__icon">
                    <FileManagerLogo />
                  </button>
                </li> */}
                {CONSTANTS.QUICKNAV[userDetail.role.role_type] && CONSTANTS.QUICKNAV[userDetail.role.role_type].map(menu => {
                  if ((menu.hasPermission && userDetail.role.hasPermission &&
                    userDetail.role.hasPermission[menu.hasPermission] &&
                    userDetail.role.hasPermission[menu.hasPermission].permission_access.read) || !menu.hasPermission) {
                    if (menu.name === "SETTINGS") {
                      return (
                        <li key={menu.name}>
                          <button className="r-nav__icon clas settings__settings-icon">
                            <NavLink activeClassName="r-nav__icon--active settings__button-color" to="/pt/settings" >
                              <i className="d-inline-block fi flaticon-gear mr-2 settings__button-color" />
                            </NavLink>
                          </button>
                        </li>
                      )
                    } else if (menu.name === "CALENDAR") {
                      return (
                        <li key={menu.name}>
                          <button className="r-nav__icon">
                            <NavLink activeClassName="r-nav__icon--active" to="/calendar" >
                              <CalendarLogo />
                            </NavLink>
                          </button>
                        </li>
                      )
                    }
                  }
                  return null
                })}

                {/* <li>
                  <button className="r-nav__icon">
                    <MessagesLogo />
                  </button>
                </li> */}
                <li>
                  <button className={"r-nav__icon" + (showNotifications ? " r-nav__icon--active" : "")} id="notifications">
                    <NotificationLogo />
                    {unread.length > 0 && <span className="r-nav__icon__count">{unread.length}</span>}
                  </button>
                  <Popover isOpen={showNotifications} toggle={this.toggleNotifications} className="notifications" trigger="legacy" placement="bottom" target="notifications">
                    <PopoverBody>
                      <Row noGutters className="px-3 my-4 justify-content-between">
                        <Col xs="auto">
                          <h4 className="r-heading-7">Notifications</h4>
                        </Col>
                        <Col xs="auto">
                          <span className="r-text--grey r-subheading-4">{unread.length} Notifications</span>
                        </Col>
                      </Row>
                      <ul className="list-unstyled">
                        {
                          notifications.map(not => {
                            return (
                              <li onClick={() => this.notificationClick(not)} tabIndex="0" key={not.notification_id} className={not.notification_status === 0 ? "notifications--unread" : ""}>
                                <Row noGutters className="px-3">
                                  {/* <Col xs={2}>
                                    <img src="/images/dummy_company.png" className="img-fluid" />
                                  </Col> */}
                                  <Col xs={12}>
                                    <span className="notifications__header">{not.subject}</span>
                                    <p className="mb-0 text-truncate" dangerouslySetInnerHTML={{ __html: not.notification_body }}></p>
                                    <p>{getTimeElasped(not.notification_date)}</p>
                                  </Col>
                                </Row>
                              </li>
                            )
                          })
                        }
                      </ul>
                    </PopoverBody>
                  </Popover>
                </li>
              </ul>
            </Col>
            <Col xs="auto" className="border-left">
              <ButtonDropdown isOpen={this.state.navDropdown} toggle={this.toggleNav}>
                <DropdownToggle className="r-nav__dropdown_btn">
                  <p className="d-inline-block r-heading-6 my-0 px-1">{name}</p>
                  <i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i>
                </DropdownToggle>
                <DropdownMenu className="r-nav__dropdown p-0">
                  <Row className="px-3 pt-3 justify-content-center align-self-center">
                    <Col xs={3}><img height="50" width="50" className="rounded-circle border" alt="" /></Col>
                    <Col xs={9} className="justify-content-center align-self-center">
                      <h1 className="r-subheading-3">{name}</h1>
                      {/* <p>Indian Institute of Management, Gujarat</p> */}
                    </Col>
                  </Row>
                  <Row className="bg-dark r-text--light p-3 text-center w-100 m-0 mt-2" >
                    {userDetail.role.role_type=="PT" && userDetail.role.hasPermission.campusProfile && userDetail.role.hasPermission.campusProfile.permission_access.read ?  <Col className="r-pointer"><Link className="text-white text-decoration-none" to={"/pt/college"} > <i className="d-inline-block fi flaticon-portfolio mr-2 "></i> Home </Link></Col> : ""}
                    <Col className="r-pointer" onClick={logout} ><i className="d-inline-block fi flaticon-upload rotate--90 mr-2"></i> Logout</Col>
                  </Row>
                  {
                    userDetail.user.roles && userDetail.user.roles.map(role => {
                      return (
                        <Row key={role.id} id={`role-${role.assigned_role_id}`} className={"p-3 border-bottom  w-100 m-0 r-pointer " + (userDetail.role.assigned_role_id == role.assigned_role_id ? "r-nav__active" : "")} onClick={async() => await this.setRole(role)}>
                          <Col><h1 className="r-subheading-4 my-1 r-text--grey-4">{role.role_name} - {role.instituteDetail.institute_name}</h1></Col>
                        </Row>
                      )
                    })
                  }
                  {/* <Row className={"p-3 border-bottom  w-100 m-0 r-pointer " + (userDetail.role === 'Spoc' ? "r-nav__active" : "")} onClick={() => this.setRole("Spoc")}>
                    <Col><h1 className="r-subheading-4 my-1 r-text--grey-4">SPOC</h1></Col>
                  </Row>
                  <Row className={"p-3 border-bottom  w-100 m-0 r-pointer " + (userDetail.role === 'PT Team' ? "r-nav__active" : "")} onClick={() => this.setRole("PT Team")}>
                    <Col><h1 className="r-subheading-4 my-1 r-text--grey-4">Placement Team</h1></Col>
                  </Row>
                  <Row className={"p-3 border-bottom  w-100 m-0 r-pointer " + (userDetail.role === 'Verifier' ? "r-nav__active" : "")} onClick={() => this.setRole("Verifier")} >
                    <Col><h1 className="r-subheading-4 my-1 r-text--grey-4" >Verifier</h1></Col>
                  </Row>
                  <Row className={"p-3 border-bottom  w-100 m-0 r-pointer " + (userDetail.role === 'Student' ? "r-nav__active" : "")} onClick={() => this.setRole("Student")}>
                    <Col><h1 className="r-subheading-4 my-1 r-text--grey-4">Student</h1></Col>
                  </Row> */}
                  <Row className="p-3 r-bg--grey-2 w-100 m-0 text-center justify-content-center align-self-center">
                    {(userDetail.role.role_type === "PT" || userDetail.role.role_type === "STUDENT") && <Col className="border-right">
                      <Link to={"/faq"} >
                        <h1 className="r-subheading-4 m-0 r-text--grey-4">FAQs</h1>
                      </Link>

                    </Col>}
                    <Col className="border-right"><h1 className="r-subheading-4 m-0 r-text--grey-4">Help</h1></Col>
                    <Col><h1 className="r-subheading-4 m-0  r-text--grey-4"><a href="https://reculta.com/privacy.pdf" className="r-text--grey-4" target="_blank" rel="noopener noreferrer">Privacy</a></h1></Col>
                  </Row>

                </DropdownMenu>
              </ButtonDropdown>
              <div>
              </div>
            </Col>


          </Row>
        </Container>
      </div>
      {expiryDaysLeft !== null && expiryDaysLeft <=  30 && 
        <div className='w-100 bg-danger text-light p-3 text-center'>
          <span>{expiryDaysLeft} days left with your Reculta subscription. Kindly renew and make the payment before {moment(userDetail.user.current_role_info.subscription_end_date).format("Do MMM'YY")} to avoid losing access.</span>
        </div>
      }
    </>
    )
  }
}

export const Nav = withRouter(Navigation)
