import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import Aux from '../../global/AuxComp';
import {Row,Col,Container,Collapse} from 'reactstrap';
import Button from '../../global/Button';
import { Auth } from '../../../actions/Auth';
import {CONSTANTS} from '../../../constants';
import { InputField } from '../../global/Forms';
import { Switch } from '../../global/Forms/ToggleSwitch';
import ShowCollege from './show_college';
import AddCollegeCollapse from './add_college_collapse';
import { SingleDatePicker } from '../../global/Forms/DatePicker';


  export default class ShowUniversity extends Component{
    constructor(props){
       super(props);
         this.state = { 
           isOn: false, 
           isEditable:false, 
           degreeList : this.props.degreeList || [],
           isEdit : false,
           subscription_end_date: this.props.instituteData.subscription_end_date || null
          };
     }

	  handleToggle(e) {
  		this.setState({isOn: !this.state.isOn});
    }
    
    editUniversityDetails = async(e)=>{
      e.preventDefault();
      this.props.toggleLoading(true)
      let params = {
        "university_id"  : this.props.instituteData.university_id,
        "city" : e.target.universityCity.value,
        "state" : e.target.universityState.value,
        "address" : e.target.universityAddress.value,
        "zip_code" : e.target.universityZipcode.value,
        "name" : e.target.universityName.value,
        subscription_end_date: this.state.subscription_end_date
      }
      try{
        await Auth.University.editUniversity(params);
        this.setState({isEdit : false})
        this.props.handleFetchInstitute();
      }
      catch(e){
        console.log(e);
      }
      this.props.toggleLoading(false)
    }

    render(){
      let { universityIndex, collegeIndex, data, instituteData, modifyDetails, modifyObjDetails, deleteDetails, addDetailsExistedList, createAppend, removeAppend} = this.props;
      var accessLevel_ids = { university_id : instituteData.university_id}
      let { degreeList, isEdit } = this.state;
      var role_count = instituteData.role_count && Array.isArray(instituteData.role_count) ? instituteData.role_count : [];
      var spocCount = role_count.filter(cd => cd.role_type === CONSTANTS.ROLE_TYPES_ID.SPOC);
      let expiryDaysLeft = moment(instituteData.subscription_end_date).diff(moment(), "days");
      return (
        <div className="child-boundry-campus-left">
          <Row className="pt-3 border-top align-items-center">
          <Col xs={4}>
            <Row className="align-items-center">
            <Col>
              <span className="r-heading-6">{instituteData.name} </span>
              {!!instituteData.subscription_end_date && <span className={'ml-3 px-2 small border border-secondary '+ (expiryDaysLeft <= 30 ? "bg-danger text-light border-danger" : "bg-light")} style={{borderRadius: 24}}>{expiryDaysLeft} Days Left</span>}
            </Col>
            </Row>
          </Col>
          <Col xs={8}>
          <Row className="align-items-center text-center">
            <Col xs={2}>
              {/* <Button onClick={() => this.props.openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.PT, accessLevel_ids)} className="" color="link-blue">{ ptCount[0] ? ptCount[0].id_count : 0 }<span className="r-text--sm"> Members <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button> */}
            </Col>
            <Col xs={2}></Col>
            <Col xs={2}></Col>
            <Col xs={2}><Button onClick={() => this.props.openMembersModel(true, CONSTANTS.ROLE_TYPES_ID.SPOC, accessLevel_ids)} className="" color="link-blue">{ spocCount[0] ? spocCount[0].id_count : 0 } <span className="r-text--sm"> Admin <i className="d-inline-block fi flaticon-eye mr-2"></i></span></Button></Col>
            <Col xs={1}><Switch  className="mt-3 d-inline-block" isOn={this.state.isOn} handleToggle={this.handleToggle.bind(this)} /></Col>
            <Col xs={3}>
                <div className="text-right">
                  <Button onClick={(e) => this.props.openAddCollege(instituteData.university_id)} className="r-text--sm mt-1 mr-3" color="link-blue"><i className="d-inline-block fi flaticon-arrow rotate--90 mr-2"></i>Add College</Button>
                  <Button onClick={() => this.setState({isEdit : true})}  className="p-2"><i className="fi flaticon-edit"></i></Button>
                  <Button onClick={() => deleteDetails(universityIndex, null,null, null)}  className="p-2"><i className="fi flaticon-bin"></i></Button>
                </div>
            </Col>
          </Row>
          </Col>
          </Row>
          { isEdit &&
          <Collapse className="border p-3 m-3" isOpen={true} >
            <Row>
              <Col className="m-3">
                <span className="modal-title r-heading-6">Edit University Details</span>
              </Col>
            </Row>
              <form onSubmit={this.editUniversityDetails}>
              <Row>
                <Col xs="4" className="m-2">
                  <InputField placeholder="University Name" name="universityName" type="text"  defaultValue={instituteData.name}  className="w-100 border-0"  required={true}/>
                </Col>
                <Col xs="4" className="m-2">
                  <InputField placeholder="State" name="universityState" type="text"  defaultValue={instituteData.state}  className="w-100 border-0" />
                </Col>
                <Col xs="4" className="m-2">
                  <InputField placeholder="City" name="universityCity" type="text"  defaultValue={instituteData.city}  className="w-100 border-0" />
                </Col>
                <Col xs="4" className="m-2">
                  <InputField placeholder="Address" name="universityAddress" type="text" defaultValue={instituteData.address}  className="w-100 border-0" />
                </Col>
                <Col xs="4" className="m-2">
                  <InputField placeholder="ZipCode" name="universityZipcode" type="text" pattern="[0-9]{6}" defaultValue={instituteData.zip_code} className="w-100 border-0" />
                </Col>
                <Col xs="4" className="m-2">
                    <SingleDatePicker
                      value={this.state.subscription_end_date}
                      onDateChange={(date) => this.setState({ subscription_end_date: moment(date).format("YYYY-MM-DD HH:mm:ss") })}
                      id="subscription_end_date" />                      
                </Col>
              </Row>
              <Row>
              <Col align="end">
                <Button color="dark" className="mt-2" > Save </Button>
                <Button type="button" className="r-bg--light r-text--dark mt-2 ml-2" onClick={()=>this.setState({isEdit : false})}>Cancel</Button>
              </Col>
              </Row>
              </form>
            </Collapse>
          }
          <Row noGutters>
            <Col className="pl-5">
            { <AddCollegeCollapse {...this.props} isOpen={this.props.isOpen === instituteData.university_id} />}
            </Col>
          </Row>
          <Row noGutters>
            <Col className="pl-5">
            {
            instituteData.college.map((child, index) => (!child.rip ? <ShowCollege key={index} universityIndex={universityIndex} collegeIndex={index}  degreeList={degreeList} {...this.props} instituteData={child} />  : null) )
          }
            </Col>
          </Row>
          
        </div>

    )
  }
}
