import moment from 'moment';
import { TEMPLATE_CONFIGPATHS, academicDetail, proDetail, personalDetail, cvPointDetails, porDetail, ecDetail, projDetail } from '../constants/template-1';
import { CONSTANTS } from '../constants';
import { Auth } from './Auth';

/**
 * @author Akshay Arora
 * @description This function gives the JSX Style Object
 * @param {Object} styleObj CSS Styles Object
 * @returns {Object} JSX Style Object
 */
export const generateInlineStyles = (styleObj) => {
  let resovledStyle = {}
  if (!styleObj) return
  Object.keys(styleObj).forEach(style => {
    let propName = cssNameToJsName(style)
    resovledStyle[propName] = styleObj[style]
  })
  return resovledStyle
}

/**
 * @author Akshay Arora
 * @description This function gives the JS equivalent for a CSS property name
 * @param {String} name CSS Property Name
 * @returns {String} Equivalent JS Property Name
 */
const cssNameToJsName = (name) => {
  var split = name.split("-");
  var output = "";
  for (var i = 0; i < split.length; i++) {
    if (i > 0 && split[i].length > 0 && !(i === 1 && split[i] === "ms")) {
      split[i] = split[i].substr(0, 1).toUpperCase() + split[i].substr(1);
    }
    output += split[i];
  }
  return output;
}

export const getFullName = ({ first_name, middle_name, last_name }) => {
  let fullName = [];
  if (first_name) fullName.push(first_name)
  if (middle_name) fullName.push(middle_name)
  if (last_name) fullName.push(last_name)
  return fullName.join(" ")
}

export const getErrorMessage = e => {
  let message = null;
  if (e.response && e.response.data) {
    message = e.response.data.error || e.response.data.message
  }
  return message
}

export const hasExtension = (fileName, exts) => {
  return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
}
export const resolveValuesFromObject = (path, srcObject, type = "TEXT", format = 'yyyy-mm-dd',itemData=[],index=-1) => {
  var result_part = "";
  if (path.constructor === Array) {
    for (var i = 0, len = path.length; i < len; i++) {
      var value = resolveValuesFromObject(path[i], srcObject, type, format,path, i);
      if (!value) {
        if (value !== "" && value !== 0)
          value = path[i]
      }
      result_part += value;
    }
    return result_part;
  } else {
    path = path.split('.');
    var current = srcObject;
    while (path.length) {
      if (typeof current !== 'object') return undefined;
      current = current[path.shift()];
    }
    if (current === null) {
      current = "";
      return current
    }
    if (type === "DATE") {
      current = current ? moment(current).format(format) : current
    }
    if (type === "CSV") {
      current = (current && current !== CONSTANTS.DELIMITER) ? current.split(CONSTANTS.DELIMITER).join(format) : "";
    }
    if (type === "SCORE") {
      current = current === "CGPA" ? "" : current;
    }
    if (type === "CSVLIST") {
      let temp = (current && current !== CONSTANTS.DELIMITER) ? current.split(CONSTANTS.DELIMITER) : [];
      current = "<ul style='padding-left:30px;list-style-type: disc'>"
      temp.forEach(item => {
        current += `<li>${item}</li>`
      })
      current += "</ul>"
    }
    if (type === "NAME") {
      current = getFullName(current)
    }
    if (type === "SCOREDECIMAL") {
      if (isNaN(parseFloat(current)))
        current = current === "CGPA" ? "" : current;
      else
        current = parseFloat(current).toFixed(2)
    }
    if (type === "PROFILELINKS") {
      let tempObj = {};
      try {
        tempObj = JSON.parse(current)
        current = tempObj[format] || ""
      } catch (e) { }
    }
    if (type === "RANK") {
      current = current == 0 ? "-" : current;
    }
    if (type === "YEARRANGE") {
      if(itemData.length && index>=0 && index===itemData.length-1)
        current = current ? moment(current).format("YY"): current
        if(itemData.length && index>=0 && index===0)
        current = current ? moment(current).format("YYYY"): current
    }
    return current;
  }
}

export const setValuesToObject = (path, target, value) => {
  path = path.split('.');
  var current = target;
  while (path.length - 1) {
    if (typeof current !== 'object') return undefined;
    current = current[path.shift()];
  }
  current[path[0]] = value
  return target;
}

export const getSelectionContainerElement = (path) => {
  var range, sel, container;
  if (path) {
    return { dataset: { configpath: TEMPLATE_CONFIGPATHS[path] } }
  }
  if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    return range.parentElement();
  }
  else if (window.getSelection) {
    sel = window.getSelection();
    if (sel.getRangeAt) {
      if (sel.rangeCount > 0)
        range = sel.getRangeAt(0);
    }
    else {
      // Old WebKit selection object has no getRangeAt, so
      // create a range from other selection properties
      range = document.createRange();
      range.setStart(sel.anchorNode, sel.anchorOffset);
      range.setEnd(sel.focusNode, sel.focusOffset);
      // Handle the case when the selection was selected backwards (from the end to the start in the document)
      if (range.collapsed !== sel.isCollapsed) {
        range.setStart(sel.focusNode, sel.focusOffset);
        range.setEnd(sel.anchorNode, sel.anchorOffset);
      }
    }
    if (range) {
      container = range.commonAncestorContainer;
      // Check if the container is a text node and return its parent if so
      return container.nodeType === 3 ? container.parentNode : container;
    }
  }
}

export const sortObjectArray = (key) => {
    return(a, b) => {
      if (a[key] > b[key]) return 1
      if (a[key] < b[key]) return -1
      return 0
  }
}

export const generateStudentCVDetails = (studentCVDetails, studentDetails) => {
  studentCVDetails.keys = {}
  Object.keys(studentCVDetails).forEach(key => {
    let section = studentDetails[key];
    if (section) {
      if (Array.isArray(section)) {
        studentCVDetails.keys[key] = []
        section.forEach(detail => {
          let id = detail.academic_id || detail.professional_id || detail.project_id || detail.id || detail.student_skill_id || detail.cv_point_id || detail.extra_curricular_id || detail.achievement_id;
          studentCVDetails.keys[key].push(id)
          studentCVDetails[key][id] = detail;
        })
      } else {
        studentCVDetails[key] = studentDetails[key]
      }
    }
  })
  studentCVDetails.files = studentDetails.files;
  return studentCVDetails;
}

export const generateMasterCV = (studentDetails, config) => {
  let academicData = { keys: [], data: [] },
    internships = { keys: [], data: [] },
    internshipDuration = 0,
    workEx = { keys: [], data: [] },
    workExDuration = 0,
    projects = { keys: [], data: [] },
    por = { keys: [], data: [] },
    ec = { keys: [], data: [] },
    academicCVPoints = { points: {}, cvpoints: {}, keys: [] },
    professionalCVPoints = {},
    projectCVPoints = {},
    porCVPoints = {},
    ecCVPoints = {},
    age = moment().diff(studentDetails.user_info.dob, "years"),
    languages = Object.keys(studentDetails.skill).map(s => studentDetails.skill[s]).filter(s => s.skill_type === "LANGUAGE").map(s => s.skill).join(", "),
    cvPointMethod = (config.methods && config.methods.cvPointDetails) ? new Function('cvPoints', config.methods.cvPointDetails) : cvPointDetails,
    proMethod = (config.methods && config.methods.proDetail) ? new Function('detail', 'i', 'cvPoints', config.methods.proDetail) : proDetail,
    projMethod = (config.methods && config.methods.projDetail) ? new Function('detail', 'i', 'cvPoints', config.methods.projDetail) : proMethod,
    acadMethod = (config.methods && config.methods.academicDetail) ? new Function('detail', 'i', 'allCVPoints', config.methods.academicDetail) : academicDetail,
    porMethod = (config.methods && config.methods.porDetail) ? new Function('detail', 'i', 'cvPoints', config.methods.porDetail) : porDetail,
    ecMethod = (config.methods && config.methods.ecDetail) ? new Function('detail', 'i', 'cvPoints', config.methods.ecDetail) : ecDetail;
  window.cvPointDetails = cvPointMethod
  window.CONSTANTS = CONSTANTS
  // Filter cv points CV Points
  Object.keys(studentDetails.cv_point).forEach((id) => {
    let cv = studentDetails.cv_point[id];
    if (cv.header && cv.text) {
      if (cv.profile_type === "ACADEMIC") {
        if (academicCVPoints.points[cv.profile_id])
          academicCVPoints.points[cv.profile_id].push(cv)
        else
          academicCVPoints.points[cv.profile_id] = [cv]
        if (academicCVPoints.cvpoints[cv.header]) {
          academicCVPoints.cvpoints[cv.header].push(cv);
        }
        else
          academicCVPoints.cvpoints[cv.header] = [cv]
        academicCVPoints.keys.push(id)
      } else if (cv.profile_type === "PROFESSIONAL") {
        if (professionalCVPoints[cv.profile_id])
          professionalCVPoints[cv.profile_id].push(cv)
        else
          professionalCVPoints[cv.profile_id] = [cv]
      } else if (cv.profile_type === "PROJECT") {
        if (projectCVPoints[cv.profile_id])
          projectCVPoints[cv.profile_id].push(cv)
        else
          projectCVPoints[cv.profile_id] = [cv]
      } else if (cv.profile_type === "POR") {
        if (porCVPoints[cv.profile_id])
          porCVPoints[cv.profile_id].push(cv)
        else
          porCVPoints[cv.profile_id] = [cv]
      } else if (cv.profile_type === "EXTRA_CURRICULAR") {
        if (ecCVPoints[cv.profile_id])
          ecCVPoints[cv.profile_id].push(cv)
        else
          ecCVPoints[cv.profile_id] = [cv]
      }
    }
  });
  // Academic Details
  studentDetails.keys.academic_detail.forEach((acad) => {
    academicData.keys.push(acad)
    academicData.data.push(acadMethod(studentDetails.academic_detail[acad], acad, academicCVPoints.points))
  })
  config.section_config.personal_details.section_layout.components.rows[2].columns[0].items[0] = (config.methods && config.methods.personalDetail) ? new Function('age', 'language', 'userInfo', config.methods.personalDetail)(age, languages, studentDetails.user_info) : personalDetail(age, languages)
  config.section_config.academic_details.keys = academicData.keys
  config.section_config.academic_details.section_layout.components.rows[1].columns[0].rows = academicData.data
  config.section_config.academic_details.cv_keys = academicCVPoints.keys
  if (config.section_config.academic_details.section_layout.components.rows[2])
    config.section_config.academic_details.section_layout.components.rows[2].columns[0].rows = cvPointMethod(academicCVPoints.cvpoints)

  let groupProfessionalCVPoints = {}
  Object.keys(professionalCVPoints).forEach((profileId) => {
    if (!groupProfessionalCVPoints[profileId])
      groupProfessionalCVPoints[profileId] = {}
    professionalCVPoints[profileId].forEach(cv => {
      if (groupProfessionalCVPoints[profileId][cv.header])
        groupProfessionalCVPoints[profileId][cv.header].push(cv)
      else
        groupProfessionalCVPoints[profileId][cv.header] = [cv]
    })
  })
  // Internships
  studentDetails.keys.professional_detail.forEach((i) => {
    let pro = studentDetails.professional_detail[i];
    if (pro.experience_type === "INTERNSHIP" || pro.experience_type === "SUMMER_INTERNSHIP") {
      internshipDuration += moment(pro.end_date||moment()).diff(pro.start_date, "weeks")
      internships.data.push(proMethod(pro, i, groupProfessionalCVPoints))
      internships.keys.push(i)
    }
  })
  config.section_config.professional_details_internship.keys = internships.keys;
  config.section_config.professional_details_internship.section_layout.components.rows[0].columns[1].items[0].data = internshipDuration + " Weeks"
  config.section_config.professional_details_internship.section_layout.components.rows[1].columns[0].rows = internships.data;
  // Work Experience
  studentDetails.keys.professional_detail.forEach((i) => {
    let pro = studentDetails.professional_detail[i];
    if (pro.experience_type === "FULL_TIME") {
      workExDuration += moment(pro.end_date || moment()).diff(pro.start_date, "days")
      workEx.data.push(proMethod(pro, i, groupProfessionalCVPoints))
      workEx.keys.push(i)
    }
  })
  config.section_config.professional_experience.keys = workEx.keys;
  config.section_config.professional_experience.section_layout.components.rows[0].columns[1].items[0].data = Math.floor(workExDuration / 30) + " Months"
  config.section_config.professional_experience.section_layout.components.rows[1].columns[0].rows = workEx.data;
  // Projects
  let groupProjectCVPoints = {}
  Object.keys(projectCVPoints).forEach((profileId) => {
    if (!groupProjectCVPoints[profileId])
      groupProjectCVPoints[profileId] = {}
    projectCVPoints[profileId].forEach(cv => {
      if (groupProjectCVPoints[profileId][cv.header])
        groupProjectCVPoints[profileId][cv.header].push(cv)
      else
        groupProjectCVPoints[profileId][cv.header] = [cv]
    })
  })
  studentDetails.keys.project.forEach((i) => {
    let pro = studentDetails.project[i]
    projects.data.push(projMethod(pro, i, groupProjectCVPoints));
    projects.keys.push(i);

  })
  config.section_config.projects.keys = projects.keys
  config.section_config.projects.section_layout.components.rows[1].columns[0].rows = projects.data;
  // POR
  let groupPORCVPoints = {}
  Object.keys(porCVPoints).forEach((profileId) => {
    if (!groupPORCVPoints[profileId])
      groupPORCVPoints[profileId] = {}
    porCVPoints[profileId].forEach(cv => {
      if (groupPORCVPoints[profileId][cv.header])
        groupPORCVPoints[profileId][cv.header].push(cv)
      else
        groupPORCVPoints[profileId][cv.header] = [cv]
    })
  })
  studentDetails.keys.position_of_responsibility.forEach((i) => {
    let pro = studentDetails.position_of_responsibility[i]
    por.data.push(porMethod(pro, i, groupPORCVPoints));
    por.keys.push(i);

  })
  if (config.section_config.positions_of_responsibility && config.section_config.positions_of_responsibility.section_layout.components.rows[1]) {
    config.section_config.positions_of_responsibility.keys = por.keys
    config.section_config.positions_of_responsibility.section_layout.components.rows[1].columns[0].rows = por.data;
  }

  // Extra Curricular
  let groupECVPoints = {}
  Object.keys(ecCVPoints).forEach((profileId) => {
    if (!groupECVPoints[profileId])
      groupECVPoints[profileId] = {}
    ecCVPoints[profileId].forEach(cv => {
      if (groupECVPoints[profileId][cv.header])
        groupECVPoints[profileId][cv.header].push(cv)
      else
        groupECVPoints[profileId][cv.header] = [cv]
    })
  })
  studentDetails.keys.extra_curricular_activity.forEach((i) => {
    let pro = studentDetails.extra_curricular_activity[i]
    ec.data.push(ecMethod(pro, i, groupECVPoints));
    ec.keys.push(i);

  })
  if (config.section_config.extra_curricular_activity && config.section_config.extra_curricular_activity.section_layout.components.rows[1]) {
    config.section_config.extra_curricular_activity.keys = ec.keys
    config.section_config.extra_curricular_activity.section_layout.components.rows[1].columns[0].rows = ec.data;
  }

  //Custom Sections
  Object.values(config.section_order).forEach(val => {
    if (config.methods && config.methods[val]) {
      config = new Function('config', 'studentDetails', config.methods[val])(config, studentDetails)
    }
  })

  return config
}


export const filterCVPoints = (cvPoints, type) => {
  let formattedPts = {};
  cvPoints.forEach(cv => {
    if (cv.profile_type === type) {
      if (formattedPts[cv.profile_id])
        formattedPts[cv.profile_id].push(cv)
      else
        formattedPts[cv.profile_id] = [cv]
    }

  })
  return formattedPts
}

export const groupCVHeaders = (cvPoints) => {
  let headers = {}
  cvPoints.forEach(cv => {
    if (headers[cv.header])
      headers[cv.header].push(cv)
    else
      headers[cv.header] = [cv]
  })
  return headers;
}

export const uploadFile = async (file, container, file_id) => {
  try {
    let fileData = await Auth.User.getS3UploadURL({container, file_name: file.name});
    let file_url = Object.keys(fileData.data)[0]
    if(!file_url) throw new Error("Unable to upload file. Please try again later.")
    await Auth.User.uploadFileToS3(fileData.data[file_url], file, {headers: {"Content-Type": file.type}})
    let fileMangerData = {
      file_url,
      file_name: file.name
    }
    if (file_id && parseInt(file_id) > 0) fileMangerData.file_id = file_id
    let fileInfo = await Auth.User.addFileToManager(fileMangerData)
    return fileInfo.data[0]
  } catch (e) {
    console.log(e)
  }
}

export const replaceFile = async (file, container, existingFile) => {
  let file_id = null;
  if (existingFile) {
    file_id = existingFile.file_id
    await Auth.User.deleteFile(existingFile.id)
  }
  let newFile = await uploadFile(file, container, file_id);
  return newFile;
}

export const getFiles = async (fileIds) => {
  let filesList = [], files = {};
  let ids = fileIds.filter(id=>id!=0)
  if(ids.length===0)
    return files
  try {
    let filesResp = await Auth.User.getFilesList(ids),
      filesList = filesResp.data,
      urlsResp = {}
    if (filesList.length)
      urlsResp = await Auth.User.getFileUrlList(filesList);
    filesList.forEach(file => {
      file.url = urlsResp.data[file.file_url]
    })
    filesList.forEach(file => {
      if (files[file.file_id])
        files[file.file_id].push(file)
      else
        files[file.file_id] = [file]
    })
  } catch (e) {
    console.log(e)
  }
  return files
}

export const getFilesList = async (fileIds) => {
  let filesList = [], files = {};
  let ids = fileIds.filter(id=>id!=0)
  if(ids.length===0)
    return files
  try {
    let filesResp = await Auth.User.getFilesList(ids),
      filesList = filesResp.data
    filesList.forEach(file => {
      if (files[file.file_id])
        files[file.file_id].push(file)
      else
        files[file.file_id] = [file]
    })
  } catch (e) {
    console.log(e)
  }
  return files
}

export const getTimeElasped = (date) => {
  let duration = null, timeunit = "";
  let now = moment();
  let units = { "seconds": 60, "minutes": 60, "hours": 24, "days": 7, "weeks": 4 };
  Object.keys(units).some((unit) => {
    if (duration === null || duration > units[unit]) {
      duration = Math.ceil(now.diff(date, unit, true))
      timeunit = unit
    } else
      return true
  })
  if (duration === 1)
    timeunit = timeunit.substring(0, timeunit.length - 1)
  return `${duration} ${timeunit} ago`
}

export const getRoleDetails = async (roles) => {
  let institute = {
    university_id: [],
    college_id: [],
    campus_id: []
  },
    campusList = {}, collegeList = {}, universityList = {};
  roles.forEach(role => {
    let { college_id, campus_id, university_id } = role.instituteDetail;
    if (campus_id > 0) institute.campus_id.push(campus_id)
    else if (college_id > 0) institute.college_id.push(college_id)
    else if (university_id > 0) institute.university_id.push(university_id)
  })
  let params = new URLSearchParams();
  Object.keys(institute).forEach(key => {
    institute[key].forEach(id => {
      params.append(key, id)
    })
  })
  let instituteResp = await Auth.Spoc.getInstituteDetails(params)
  if (instituteResp.data.campusDetails) {
    campusList = instituteResp.data.campusDetails.reduce((prev, current) => {
      prev[current.campus_id] = current.name;
      return prev
    }, {})
  }
  if (instituteResp.data.collegeDetails) {
    collegeList = instituteResp.data.collegeDetails.reduce((prev, current) => {
      prev[current.college_id] = current.name;
      return prev
    }, {})
  }
  if (instituteResp.data.universityDetails) {
    universityList = instituteResp.data.universityDetails.reduce((prev, current) => {
      prev[current.university_id] = current.name;
      return prev
    }, {})
  }
  roles = roles.filter(role => {
    if (role.hasPermission && role.hasPermission.shortlistingVerification && role.hasPermission.shortlistingVerification.create === 1) {
      window.shortlistingVerifierId = role.role_id
      return false
    }
    return true
  })
  roles.forEach(role => {
    let { college_id, campus_id, university_id } = role.instituteDetail;
    role.instituteDetail.institute_name = campusList[campus_id] || collegeList[college_id] || universityList[university_id] || ""
  })
  return roles
}

export const getQueryParams = (qs) => {
  qs = qs.split('+').join(' ');

  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

  while (tokens = re.exec(qs)) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
}

export const checkPass = (pw) => {
  let regx = /^(?=(?:[^a-z]*[a-z]){1})(?=(?:[^A-Z]*[A-Z]){1})(?=(?:\D*\d){1})(?=(?:[^!@#$%^&*)(]*[!@#$%^&*)(]){1}).{8,}$/;
  return regx.test(pw);
}

export const generateRedirectURL = ({ entity_type, entity_id }) => {
  if (entity_id && entity_type)
    return CONSTANTS.NOTIFICATION[entity_type](entity_id)
  return ""
}

export const formattedMatrixError = error => {
  var formattedError = [];
  error.forEach((error) => {
    var name = error.matrixRule.rule_type == 'max_limit' ? "Overall" : (error.matrixRule.rule_type == 'classification_each' ? error.matrixRule.classification_name : error.matrixRule.classification_category_name);
    formattedError.push(`The ${name} applications limit is ${error.matrixRule.apply_limit} and you have applied to ${error.apply_count} companies`);
  })
  return formattedError
}
