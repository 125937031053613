import React, { Component } from 'react';
import {Row, Col, Input , Modal, ModalBody, Collapse, Badge} from 'reactstrap';
import { InputField } from '../../../global/Forms';
import { Select } from '../../../global/Forms/Select';
import { Stars } from '../../../global/Forms/stars';
import Button from '../../../global/Button';
import moment from 'moment';
import store from '../../../../store';
import { Auth } from '../../../../actions/Auth';
import { CompanyContact } from './contact-card';
import { ContactSection } from './contact_section';
import { RolesSection } from './roles_section';
import { CONSTANTS } from '../../../../constants';
import { MeetingStatus } from '../process/meeting-status';
import { getFullName } from '../../../../actions';
import AddRecruiter from './add_recuiter';
import {Confirmation} from '../../../global/confirmation';
import { setError } from '../../../../actions/action-creators';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Link from 'react-router-dom/Link';
import AddMinutes from '../process/add-minutes';
import UncontrolledPopover from 'reactstrap/lib/UncontrolledPopover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import UncontrolledCollapse from 'reactstrap/lib/UncontrolledCollapse';
import { RTE } from '../../../global/Forms/RTE';



export class CompanyCard extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      readMoreFeedback : 0,
      isOpenDeleteModal : false, 
      addProcessModal: false, 
      process: '', 
      processList: this.props.processList || [], 
      isOpenAddProcess : false, 
      isOpenAddRecuriter : false,
      priority : 0, 
      checkbox : false, 
      mappedProcess : [], 
      isOpenCollapse : 0, 
      contactList : [], 
      emailTemplates : [], 
      meetingList : [], 
      meetingInteractionList : [], 
      classificationDetails : this.props.classificationDetails || null,
      isShowRecruiter : false,
      isRecruiterDelete : false,
      isCampusDescription : false,
      isOpenAddInteraction: false
    };
  }
  stringToHslColor(str) {
    var hash = 0;
    if(str)
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
    var h = hash % 360;
    return {bg : 'hsl('+h+', '+80+'%, '+80+'%)', text : 'hsl('+(h)+', '+80+'%, '+30+'%)'};
  }
  updatePriority = (index) => {
    this.setState({priority : index});
  }
  toggleAddProcess =() => {
    this.setState({isOpenAddProcess : !this.state.isOpenAddProcess});
  }
  toggleDeleteModal = async() => {

    this.setState({isOpenDeleteModal : !this.state.isOpenDeleteModal});
  }
  deleteCompany = () => {
    this.setState({isOpenDeleteModal : false})
    this.props.deleteCompany([this.props.companyData.campus_company_id])
  }
  mapToProcess = (e) => {
    this.setState({isOpenAddProcess : false})
    var process_id = this.state.process;
    this.props.mapToProcess(process_id, [this.props.companyData.campus_company_id])
  }
  toggleAddRecuriter = async () => {
    if(!this.state.isOpenAddRecuriter){
      let profileResp = await Auth.CampusProfile.fetch({campus_id : this.props.campus_id}); 
      if(profileResp.data.length && profileResp.data[0].description && profileResp.data[0].description.replace(/<\/?[^>]+(>|$)/g, "")){
        this.setState({isCampusDescription: true})
      }

    }
    this.setState({isOpenAddRecuriter : !this.state.isOpenAddRecuriter});
  }
  onChange = e => {
    if(e.target.value==="sttoDrive"){
     this.setState({addProcessModal: true})
    }
    else {this.setState({
            [e.target.name]: e.target.value,
        })}
  }

  cardOnclick = (index) => {
    if(this.props.isMultipleSelect)
      this.props.selectCard(index);
    else
      // this.props.setCompanyInfo(this.props.companyData);
      this.props.history.push("/pt/companies/"+this.props.companyData.campus_company_id);
  }

  toggleCollapse = async(value) => {
    this.props.toggleLoading(true)
    try{
      if(this.state.isOpenCollapse !== 3 && value === 3){
        var mappedProcess = await Auth.PtCompanies.getCompaniesInProcess(this.props.companyData.campus_company_id);
        this.setState({mappedProcess : mappedProcess.data})
      }
      if(this.state.isOpenCollapse !== 4 && value === 4){
        var body = {
          entity_type : "campus_company_id",
          entity_id : this.props.companyData.campus_company_id,
          campus_company_id : this.props.companyData.campus_company_id
        }
        var emailTemplates = await Auth.EmailTemplate.getTemplateDetails(body).catch(err => null);
        var meetingResp = await Auth.Meetings.getMeetings(body)
        this.setState({
          emailTemplates : emailTemplates ? emailTemplates.data : [], 
          meetingList : meetingResp.data.filter(meet => meet.meeting_category === "Meeting"),
          meetingInteractionList : meetingResp.data.filter(meet => meet.meeting_category === "InteractionHistory")
        });
      }
    }
    catch(e){

    }
    this.props.toggleLoading(false)
    this.setState({isOpenCollapse : this.state.isOpenCollapse === value ? 0 : value})
  }



    addProcess = async (e) => {
      e.preventDefault();
      let { process_name, process_type } = e.target;
      let formData = {
        process_name: process_name.value,
        process_type: process_type.value,
        campus_id: store.getState().role.instituteDetail.campus_id
      }
      Auth.Processes.addProcess(formData).then(resp => {
        if (resp.data[0]) {
          let { processList } = this.state;
          processList.push(resp.data[0])
          this.setState({
            processList,
            addProcessModal: false,
            applications: [],
            companies: [],
            processStudent : []
          })
          this.setState({addProcessModal: false})
        }
      }).catch(e => {
        console.log(e)
      })
    }
  handleAssigneeChange = async (user_id) => {
    let { campus_company_id } = this.props.companyData;
    let formData = {
      campus_company_id,
      user_id
    }
    this.props.toggleLoading(true)
    try {
      await Auth.Processes.updateCampusCompanyUser(formData)
    } catch (e) {
      if (e.response && e.response.data)
        this.setState({
          errorbar: true,
          errorMessage: e.response.data.error[0]
        })
      else
        this.setState({ errorbar: true, errorMessage: "Something went wrong" })
    }
    this.props.toggleLoading(false)
    this.props.handleDataChange(campus_company_id, {user_id});
  }

  showRecruiter = async () => {
    this.props.toggleLoading(true);
    try{
      var connectionResp = await Auth.Recruiter.Connection.getConnection({campus_id: this.props.campus_id, company_id: this.props.companyData.company_id});
      if(!connectionResp.data) throw new Error("Recruiter not found");
      this.setState({isShowRecruiter : connectionResp.data[0]});
    }
    catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: e.response && e.response.data ? e.response.data.error.toString() : "Something went wrong" }));
    }
    this.props.toggleLoading(false)
  }

  deleteRecruiter = async () => {
    this.props.toggleLoading(true);
    let { campus_company_id, company_id } = this.props.companyData;
    try{
      await Auth.Recruiter.Connection.inactiveConnection({campus_id: this.props.campus_id, company_id});
      this.props.handleDataChange(campus_company_id, {is_recruiter_created: 0});
      this.setState({isShowRecruiter : false, isRecruiterDelete : false})
    }
    catch (e) {
        this.setState({ errorbar: true, errorMessage: e.response && e.response.data ? e.response.data.error[0] : "Something went wrong" })
    }
    this.props.toggleLoading(false)
  }

  render() {
    let { companyData, isMultipleSelect, selectedCards, ptList } = this.props;
    let { classificationDetails, processList, addProcessModal } = this.state;
    var color = this.stringToHslColor(companyData.company_details.company_name);
    var mappedClassification = companyData.classifications.map((child)=> child.name)
    return (
      <div>
      <Row className={"align-items-center company-card py-3 my-4 "+(selectedCards.indexOf(companyData.campus_company_id) > -1 ? 'company-card-selected ':'')}   >
        <Col xs={3}>
          <Row  className="align-items-center" >
            {isMultipleSelect && <Col xs="auto" className="pr-0" ><input type="checkbox"  checked={selectedCards.indexOf(companyData.campus_company_id) > -1} /></Col>}
            <Col xs="auto" className="" ><div className="rounded-circle text-center" style={{backgroundColor : color.bg, width:32, height:32, fontSize:18, color:color.text}}><span className="align-middle">{companyData.company_details.company_name && companyData.company_details.company_name[0]}</span></div></Col>
            <Col xs="auto" className="" >
              <h1 onClick={() => this.cardOnclick(companyData.campus_company_id)} className="selectable r-subheading-3 mb-0 mt-1">{companyData.company_details.company_name}</h1>
              <span className="r-text--sm d-block r-text--grey-4 mt-0">{mappedClassification.join(' | ')}</span>
              {!companyData.is_recruiter_created ? 
                <h1 onClick={() => this.toggleAddRecuriter()} className="r-text--sm selectable mb-0 mt-2 r-text--blue-3"> Create Access</h1>
                : 
                <><h1 className="r-subheading-4 d-inline-block mt-2 mr-2">&#10004; Recuriter </h1><span onClick={this.showRecruiter} className="selectable r-text--blue-3 r-text--sm mt-2 fi flaticon-eye"></span></>
               }
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="align-items-center"  style={{ flexWrap: "nowrap"}}>
          {/* <Col className="text-center" onClick={() => this.toggleCollapse(1)} xs={4}><h1 className="r-subheading-4 mb-0 mt-1">{getFullName(companyData.pt_details) || " - "}</h1>
              <span className="r-text--sm r-text--grey-4 mt-0">Created By</span>
          </Col> */}
          <Col className="px-2" xs={2}>
              <div className="r-dropdown-2">
                <Select value={companyData.user_id || ""} onChange={(e) => this.handleAssigneeChange(e.target.value)}>
                  <option value="">Select</option>
                  {
                    ptList.map(pt => {
                      return <option key={pt.id} value={pt.user_id}>{getFullName(pt.userDetail)}</option>
                    })
                  }
                </Select>
              </div>
          </Col>
          {/* <Col xs={4} className="text-center" >
              <h1 className="r-subheading-3 mb-0 mt-1">Engagement</h1>
              <div className="r-text--blue-3 selectable mt-2" onClick={() => this.toggleCollapse(4)}>
                <span>{companyData.emailCount || 0}</span> 
                <span className="fi flaticon-mail ml-2"></span></div>
              
            </Col> */}

          <Col xs={4} className="selectable r-text--blue-3 text-center" onClick={() => this.toggleCollapse(4)}>
              <span className="r-subheading-3 mb-0 mt-1">{companyData.emailCount || 0}<span className="fi flaticon-mail ml-2"></span></span>
              <span className="r-subheading-3 mb-0 mt-1 ml-4">{companyData.meetingCount || 0}<span className="fi flaticon-calendar ml-2"></span></span>
              <span className="r-subheading-3 mb-0 mt-1 ml-4">{companyData.notesCount || 0}<span className="fi flaticon-chat ml-2"></span></span>
              <span className="r-text--sm r-text--grey-4 mt-0 d-block">Engagement</span>
          </Col>
          <Col className="selectable r-text--blue-3 text-center" onClick={() => this.toggleCollapse(1)} xs={2}><h1 className="r-subheading-3 mb-0 mt-1">{companyData.contactCount || "No Contacts"}</h1>
              {companyData.contactCount ? <span className="r-text--sm r-text--grey-4 mt-0">{companyData.contactCount > 1 ? "Contacts" : "Contact"}</span> : <h1 className="r-subheading-3">Added</h1> }
          </Col>
          <Col className="selectable r-text--blue-3 text-center" onClick={() => this.toggleCollapse(2)} xs={2}><h1 className="r-subheading-3 mb-0 mt-1">{companyData.profileCount || `No Roles`}</h1>
              {companyData.profileCount ? <span className="r-text--sm r-text--grey-4 mt-0">{companyData.profileCount > 1 ? "Roles" : "Role"}</span> : <h1 className="r-subheading-3">Added</h1> }
          </Col>
          <Col className="selectable r-text--blue-3 text-center" onClick={companyData.processCount ? ()=>this.toggleCollapse(3) : this.toggleAddProcess} xs={3}><h1 className="r-subheading-3 mb-0 mt-1">{companyData.processCount || "No Placement Drive"}</h1>
              {companyData.processCount ? <span className="r-text--sm r-text--grey-4 mt-0">{companyData.processCount > 1 ? "Placement Drives" : "Placement Drive"}</span> : <h1 className="r-subheading-3">Mapped</h1> }
          </Col>
          {/*<Col xs={2}>
            <h1 className="r-subheading-3 mb-0 mt-1">{moment(companyData.created_on).format("Do MMMM YYYY")}</h1>
            <span className="r-text--sm r-text--grey-4 mt-0">Date Added</span>
          </Col>*/
          }
          </Row>
        </Col>
        <Col xs={1} className="text-right" >
          <Button onClick={this.toggleDeleteModal} color="link-blue" className="mr-2" >
            <i className="fi flaticon-bin r-text--lg" />
          </Button>
        </Col>
      </Row>

      { this.state.isOpenCollapse ?
        <Row className="mb-3">

          <Collapse isOpen={ this.state.isOpenCollapse ? true : false } className="col shadow p-3" >
          {
          this.state.isOpenCollapse === 1 ? <ContactSection toggleLoading={this.props.toggleLoading} refreshParent={this.props.refreshParent} campus_company_id={companyData.campus_company_id} />  :  ""
          }
          {
          this.state.isOpenCollapse === 2 ? <RolesSection toggleLoading={this.props.toggleLoading} refreshParent={this.props.refreshParent} campus_company_id={companyData.campus_company_id} campus_id={this.props.campus_id}/> : ""
          }
          {
          this.state.isOpenCollapse === 3 ?
          <Row>
          <Col xs={12}>
          <Row>
            <Col>
            <Button onClick={this.toggleAddProcess} className="ml-2 r-text--sm" color="brandB">  <i className="fi flaticon-plus mr-2 r-text--sm" />Map to Placement Drive</Button>
            </Col>
            </Row>
            <Row className="border-bottom m-3 p-3">
              <Col><h1 className="r-subheading-3">Drive Name</h1></Col>
              <Col><h1 className="r-subheading-3">Drive Status</h1></Col>
              <Col><h1 className="r-subheading-3">Feedback</h1></Col>
            </Row>
            {
          this.state.mappedProcess.map((data, index) =>
            <Row className="align-items-center m-3 p-3 justify-content-between border-bottom" key={index}>
              <Col xs={3}>
              <h1 className="r-subheading-3">{data.process_details.process_name} </h1>
              </Col>
              <Col  className="">
              {/* <Col  className="company_meeting_status"> */}
                <Row xs={3} className="align-items-center">
                  <Col xs={10}><MeetingStatus company={{...data, toggleLoading: this.props.toggleLoading, handleDataChange: (resp) => data.company_status = resp.company_status}} /></Col>
                  <Col xs={2} className="p-0">
                    {data.process_details.archive_status != 1 ? <Badge color="success">Active</Badge> : <Badge color="danger">Archived</Badge>}
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                  {data.feedback.map((feedback, index, arr) => 
                    <Row className={((index+1) == arr.length ? null : 'border-bottom mb-3')+" mx-0"}>
                      <Col>
                      {JSON.parse(feedback.feedback_by).name}
                      <div>
                      <Stars proficiency={feedback.feedback_rating} className="r-text--sm d-inline-block" />
                      <h1 className="ml-3 r-heading-7 d-inline-block r-text--grey-4">{moment(feedback.created_on).format('MMM D, Y')}</h1>
                      </div>
                      { this.state.readMoreFeedback == feedback.id ? 
                       <p style={{fontSize : 12}}>{feedback.feedback_comment || " "}{<Button onClick={ () => {this.setState({readMoreFeedback : 0})}} className="r-text--blue" color="link-blue"> ...Read Less</Button>}</p>
                       :
                        <p style={{fontSize : 12}}>
                         {(feedback.feedback_comment || "").substring(0,70) }{(feedback.feedback_comment || "").length > 70 ? <Button onClick={ () => {this.setState({readMoreFeedback : feedback.id})}} className="r-text--blue" color="link-blue"> ...Read More</Button> : ''}
                        </p>
                      }
                      </Col>
                    </Row>
                  )}
              </Col>
            </Row>
          )}
            </Col>
          </Row>

            :""
          }
          {
          this.state.isOpenCollapse === 4 ? 
          <Row className="text-center">
            <Col xs={4} >
              <div className="mx-2">
              <h1 className="r-subheading-3 mb-4 text-left border-bottom py-3">Email <Link to={"/pt/emailCampaign"}><Button color="blue-3 fi flaticon-plus r-text--sm"></Button></Link></h1>
              {this.state.emailTemplates.length ? <>
              {
                this.state.emailTemplates.map((template, i) => 
                  <Row className="m-0 mt-2 border py-3 align-items-center r-text--sm shadow">
                    <Col className="text-left">
                      <span className="align-middle r-subheading-4 p-0 m-0 d-block">{template.template_info.template_name}</span>
                      <span className="r-text--grey-4 r-subheading-4">{template.template_info.subject}</span>
                    </Col>
                    <Col xs={"auto"}>
                    {moment(template.created_on).utcOffset("+05:30").format("Do'MMM YYYY hh:mmA")}
                    <span id={"UncontrolledPopover"+i} className="selectable fi flaticon-eye r-text--sm r-text--blue-3 ml-2"></span>
                    <UncontrolledPopover trigger="legacy" placement="bottom" target={"UncontrolledPopover"+i}>
                      <PopoverBody><p dangerouslySetInnerHTML={{ __html: template.html }}></p></PopoverBody>
                    </UncontrolledPopover>
                    </Col>
                  </Row>
                )
              }</>: <em className="text-secondary my-3">No Data</em>}
              </div>
            </Col>
            <Col xs={4} className="border-left border-right">
              <div className="mx-2">
              <h1 className="r-subheading-3 mb-4 text-left border-bottom py-3">Meetings <Link to={"/calendar/meetings?campus_company_id="+companyData.campus_company_id}><Button color="blue-3 fi flaticon-plus r-text--sm"></Button></Link></h1>
              {this.state.meetingList.length ? <>
              {
                this.state.meetingList.map((meet, i) => 
                  <Row className="m-0 mt-2 border py-3 align-items-center r-text--sm shadow">
                    <Col className="text-left">
                      <span className="align-middle r-subheading-4 p-0 m-0 d-block">{meet.meeting_title}</span>
                      <span className="r-text--grey-4 r-subheading-4">{meet.location}</span>
                    </Col>
                    <Col xs={"auto"}>
                      {moment(meet.start_date).utcOffset("+05:30").format("Do'MMM YYYY hh:mmA")}
                      <Link to={`/calendar/meetings/${meet.meeting_id}/edit`}><span className="selectable fi flaticon-eye r-text--sm r-text--blue-3 ml-2"></span></Link>
                    </Col>
                  </Row>
                )
              }</>: <em className="text-secondary my-3">No Data</em>}
              </div>
            </Col>
            <Col xs={4}>
              <h1 className="r-subheading-3 mb-4 text-left border-bottom py-3">Interaction History <Button onClick={() => this.setState({isOpenAddInteraction: true})} color="blue-3 fi flaticon-plus r-text--sm"></Button></h1>
              {!this.state.meetingInteractionList.length && <em className="text-secondary my-3">No Data</em>}
              <AddMinutes onCloseAdd={() => this.setState({isOpenAddInteraction: false})} isOpenAdd={this.state.isOpenAddInteraction} profileData={[]} meetingData={{...companyData, list: this.state.meetingInteractionList}} handleDataChange={() => {}} />
            </Col>
          </Row>
          : null
          }
          </Collapse>
      </Row>
      :""
    }

      <Modal className="modal-dialog-centered" isOpen={this.state.isOpenDeleteModal} toggle={this.toggleDeleteModal} >
        <ModalBody>
            <h1 className="r-subheading-3">Delete</h1>
            {companyData.processCount ? <h1 className="r-subheading-4 my-5 ml-3">This Company mapped with {" "+companyData.processCount+" "} placement drive.</h1> : ""}
            <h1 className="r-subheading-4 my-5 ml-3">Do you want to delete Company `{companyData.company_details.company_name}` ?</h1>
            <Button onClick={this.deleteCompany} className="float-right mr-3" color="dark r-btn--no-radius">Delete</Button>
        </ModalBody>

      </Modal>
      <Modal className="modal-dialog-centered" isOpen={this.state.isOpenAddProcess} toggle={this.toggleAddProcess} >
        <ModalBody>
            <h1 className="r-subheading-3 border-bottom pb-3">Add to Placement Drive</h1>
            <Row className="mt-4">
              <Col xs={4}>
                <h1 className="r-subheading-4">Select Placement Drive</h1>
                <Select name="process"  onChange={this.onChange}>
                    <option value="">Select Placement Drive</option>
                    <option  value="sttoDrive" style={{color:"blue"}}> + Add Placement Drive</option>
                  {processList.map((child, index) => (<option key={index} value={child.process_id} >{child.process_name}</option>))}
                </Select>
              </Col>
              {/*classificationDetails && classificationDetails.map(classification=>
                <Col xs={4} key={classification.name}>
                <h1 className="r-subheading-4"> {classification.name} </h1>
                <Select name="classification">
                <option value="">Select</option>
                {classification.classification_list && classification.classification_list.map(list=>
                  <option key={list.classification_id} value={companyData.classifications.length!=0?companyData.classifications.filter(category =>list.classification_id== category.classification_id):list.classification_id}> {companyData.classifications.filter(category =>list.classification_id==category.classification_id)}</option>
                )
              }
                  </Select>
                  </Col>
              )*/}
            </Row>
            {/*<Row className="mt-4">
              <Col xs={6}>
                <h1 className="r-subheading-4">Priority</h1>
                { ['','','','',''].map( (child, index) => <span key={index} style={{color : this.state.priority > index ? "#c59708f0":"black"}} onClick={() => this.updatePriority(index+1)} >&#9830;{' '}</span> )}
              </Col>
            </Row>
            */}
            <Button onClick={this.mapToProcess} className="float-right mr-3 mt-4" color="dark r-btn--no-radius">Add to Placement Drive</Button>
        </ModalBody>
        <Modal zIndex="99999" isOpen={addProcessModal} toggle={() => this.setState({ addProcessModal: false})}>
          <ModalBody>
            <span className="modal__title r-heading-6">Add Placement Drive</span>
            <form className="mt-3" onSubmit={this.addProcess}>
              <label className="r-subheading-4">Placement Drive Name</label>
              <InputField defaultValue={""} required name="process_name" className="w-100" type="text" placeholder="Enter Placement Drive Name" />
              <label className="mt-2 r-subheading-4">Placement Drive Type</label>
              <Select name="process_type" defaultValue={""}>
                {
                  Object.keys(CONSTANTS.PROCESS_TYPES).map((processType) =>
                    <option key={processType} value={processType}>{CONSTANTS.PROCESS_TYPES[processType]}</option>
                  )
                }
              </Select>
              <div className="text-right mt-3">
                <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius">Save</Button>
              </div>
            </form>
          </ModalBody>
        </Modal>

      </Modal>

      <Modal className="modal-dialog-centered" isOpen={this.state.isOpenAddRecuriter} toggle={this.toggleAddRecuriter} >
        <ModalBody>
          {!this.state.isCampusDescription && <><span className="text-danger">*In the recruiter invite email, the description given in the about section is included in the email. Please fill the same </span>{' '}<Link to="/pt/college/about">here</Link></>}
          <AddRecruiter company_id={companyData.company_id} onSuccess={() => this.props.handleDataChange(companyData.campus_company_id, {is_recruiter_created: 1})} toggleAddRecuriter={this.toggleAddRecuriter}/>
        </ModalBody>
      </Modal>
      <Modal className="modal-dialog-centered" isOpen={!!this.state.isShowRecruiter} toggle={() => this.setState({isShowRecruiter : false, isRecruiterDelete : false})} >
        <ModalHeader toggle={() => this.setState({isShowRecruiter : false, isRecruiterDelete : false})}>Recruiter Details</ModalHeader>
        <ModalBody className="p-5">
          <div><b>Recruiter Name: </b><span>{this.state.isShowRecruiter.userDetails && getFullName(this.state.isShowRecruiter.userDetails)}</span></div>
          <div><b>Recruiter Email: </b><span>{this.state.isShowRecruiter.userDetails && this.state.isShowRecruiter.userDetails.email}</span></div>
        </ModalBody>
        <ModalFooter>
                {!this.state.isRecruiterDelete ? 
                  <>
                    <Button color="link-blue mr-3" onClick={() => this.setState({isRecruiterDelete : true})}>Remove  Recruiter Access</Button>
                    <Button color="dark" className="ml-2" onClick={() => this.setState({isShowRecruiter : false, isRecruiterDelete : false})}>Close</Button>
                  </>
                  : 
                  <>
                    <span><b className="r-text--sm">Are you sure to remove recruiter access?</b></span>
                    <Button color="dark mr-2 r-text--sm" onClick={this.deleteRecruiter}>Remove</Button>
                    <Button color="dark ml-1 r-text--sm" onClick={() => this.setState({isRecruiterDelete : false})}>Cancel</Button>
                  </>
                }
        </ModalFooter>
      </Modal>

      </div>
    )
  }
}
